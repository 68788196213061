/* .Topbar{
  background-color: white !important;
  height: 5rem;
} */

#popover-contained{
  border-color: #CEE9FA !important;
  max-width: 21.5rem;
  max-height: 14.7rem;
  left: -95px!important;
  z-index: 99999;
}

#popover-contained .close-btn{
  padding-top:0.5rem;
  padding-right: 0.5rem;
}

#popover-contained .popover-arrow {
  left: 95px !important;
}

#popover-contained .popover-arrow:before {
  border-bottom-color: #CEE9FA;
}

#popover-contained .popover-body{
  padding:0px;
  padding-bottom:5px ;
}

/* .profile{
  margin-left:3.5rem;
}
.menu-icon{
margin-left:3.5rem;
} */
#basic-nav-dropdown::after{
  display: none;
}

/* .profile-section{
    padding:0.5rem;
    border: 1px solid #FFFFFF;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border-bottom: none;
    width:15.6rem;
    margin-right: 3.5em;
}

.profile .dropdown-menu{
    width:15.6rem;
}*/

.profile .dropdown-menu.show{
    border-top: none;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #EEEEEE;
}

.profile .dropdown-toggle.show .profile-section{
    border: 1px solid #EEEEEE;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border-bottom: none;
} 

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: green;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  
  
  .activeSwitch .react-switch-bg{
    border:1px solid #5A5EEE;
  }


  .checkedIcon, .uncheckedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* font-size:20px; */
    color:#666666;
  }
  
  .dropdown-divider{
    border-top:1px solid #EEEEEE;
  }

  .breadcrumb-item.active {
      color: #111;
      font-size:24px;
      font-weight:medium;
  }

  /* .sketch-picker{
    width: 403px !important;
    height: 395px !important;
    border:none !important;
    border-radius: 0px !important;
} */

/* .sketch-picker > div:first-child{
  padding-bottom: 58% !important;
  border-radius: 19px !important;
  width: 378px !important;
height: 195px !important;
padding-left: 20px !important;
margin-left:10px;
border: 1px solid #707070;
} */

/* .sketch-picker > .flexbox-fix:not(:last-child){
  width: 378px !important;
  margin-left: 10px;
  margin-top: 8px;
} */

/* first hue block */
/* .hue-horizontal{
  width: 325px !important;
height: 15px !important;
border-radius: 12px;
}

.hue-horizontal div div{
  height: 13px !important;
} */

/* .sketch-picker > div:last-child{
  margin-left: 0px !important;
} */

/* first hue block */
/* .sketch-picker >div:nth-child(2) > div:nth-child(1) > div:nth-child(1){
  height: 15px !important;
} */

/* saturation block */
/* .sketch-picker >div:nth-child(2) > div:nth-child(1) > div:nth-child(2){
  width: 325px !important;
  height: 15px !important;
  border-radius: 12px;
} */

/* .sketch-picker >div:nth-child(2) > div:nth-child(1) > div:nth-child(2) div div{
  height: 15px !important;
} */

/* color showing round block */
/* .sketch-picker >div:nth-child(2) > div:nth-child(2){
  width: 36px !important;
height: 36px !important;
} */

.sketch-picker >div:nth-child(2) > div:nth-child(2)> div:nth-child(1){
  background:unset !important;
}

/* .sketch-picker >div:nth-child(2) > div:nth-child(2)> div:nth-child(2){
  border-radius: 18px !important;
} */

/* .flexbox-fix input{
  border: 1px solid #A4BFBD90;
  border-radius: 4px;
  opacity: 1;
  height: 34px;
  font-size:14px;
}

.flexbox-fix label{
  margin-right: 15px;
  height: 26px !important;
  font-size: 16px !important;
} */

.checkbox-container{
  height: 70px;
}

.table th{
  width: auto !important;
}

.page-item.disabled .page-link {
  border: 1px solid #707070;
  border-radius: 50%;
}

.page-item:last-child .page-link,
  .page-item:first-child .page-link {
    border-radius: 50%;
  }

  .page-item:not(:first-child) .page-link {
    margin-left: unset;
  } 

  .ytp-chrome-controls{
    background-color: #5A5EEE !important;
  }

  .ql-container.ql-snow{
    border:none !important;
  }

  .description-container{
    margin-bottom: 1.8rem;
  }

  .quill-image{
    width:100%;
  }

  .ck.ck-reset.ck-editor.ck-rounded-corners{
    font-size:0.834rem !important;
  }
  
  .ck.ck-toolbar:not(.ck-dropdown__panel-visible .ck.ck-toolbar,
  .ck-balloon-rotator__content .ck.ck-toolbar){
    background-color: #f7f7f7 !important;
    border:none !important;
    border-radius:0.417rem !important;
    border-bottom: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 0 0.05rem !important;
  }

  .ck.ck-editor__main>.ck-editor__editable{
    /* border:1px solid #666666 !important; */
    border: none !important;
    border-radius:0.417rem !important;
    border-top: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: none !important;
  }

  .ck.ck-toolbar__separator{
    /* display: none !important; */
    background-color: #EAEAEA !important;
  }
  
  /* .ck.ck-toolbar>.ck-toolbar__items>:not(.ck-toolbar__line-break){
    margin:0 !important;
  } */

  .ck-rounded-corners .ck.ck-button{
    border-radius: 0 !important;
  }

  .ck.ck-content.ck-editor__editable{
    max-height: 8rem !important;
    height: 8rem !important;
  }
  .ck.ck-powered-by{
    display: none !important;
  }

  .accordion-body{
    padding:0;
  }

  figure > img{
    width: 100%;
  }

  