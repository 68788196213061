body {
  background-color: #f8f8f8;
  font-family: Poppins;
  font-weight: normal;
  margin: 0;
  padding: 0;
  width: 100vw;
  /* overflow: hidden; */
}

.btn-inverse {
  color: darkgray;
  border-color: darkgray;
}
.btn-inverse:hover {
  background-color: black;
  color: white;
}

.alert-popup {
  padding: 24px 92px;
}

.ps-menu-button {
  font-family: Poppins, Regular;
  font-size: 14px;
  background-color: #2a2f44 !important;
  opacity: 0.5;
}

.ps-menu-button > span:hover {
  opacity: 1;
}

.ps-submenu-content ul li .ps-menu-button {
  padding-left: 65px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: fixed;
  z-index: 2002;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}

.activity-form {
  margin-right: -20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
